import Script from 'next/script';

const Libs = () => {
  return (
    <>
      {/* Store URL params in cookies on mount */}
      <Script
        id="url-params-cookie-script"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
          (function () {
            const maxAge = '7776000';
            const getDomain = (url) => {
              const domainParts = url.split('.');
              return domainParts.length > 2 ? domainParts.slice(-2).join('.') : url;
            };
            const getSubdomainIndex = (url) => {
              if (!url) return 1;
              const parts = url.split(".");
              if (parts[0] === 'www') return 1;
              return parts.length === 1 ? 0 : parts.length === 2 ? 1 : 2;
            };
            const subdomainIndex = getSubdomainIndex(window.location.host);
            const generateFbc = (fbclid) => 'fb.' + subdomainIndex + '.' + Date.now() + '.' + fbclid;

            const fbclid = new URLSearchParams(window.location.search).get('fbclid');
            if (fbclid) {
              const fbc = generateFbc(fbclid);
              document.cookie = '_fbc=' + fbc + '; path=/; domain=.' + getDomain(window.location.hostname) + '; max-age=' + maxAge + '; samesite=lax';
            }
          })();`,
        }}
      />
      <Script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
            window.Shopify = { shop: "${process.env.SHOPIFY_DOMAIN}" }
           `,
        }}
      />

      <Script
        type="text/javascript"
        id="social-snowball-script"
        strategy="afterInteractive"
        src={`https://api.socialsnowball.io/js/referral.js?shop=${process.env.SHOPIFY_DOMAIN}`}
      />

      <Script
        id="acsbapp-script"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
         (function(){ var s = document.createElement('script'); var h = document.querySelector('head') || document.body; s.src = 'https://acsbapp.com/apps/app/dist/js/app.js'; s.async = true; s.onload = function(){ acsbJS.init({ statementLink : '', footerHtml : '', hideMobile : false, hideTrigger : false, disableBgProcess : false, language : 'en', position : 'right', leadColor : '#404040', triggerColor : '#0C0C0C', triggerRadius : '50%', triggerPositionX : 'left', triggerPositionY : 'bottom', triggerIcon : 'people', triggerSize : 'bottom', triggerOffsetX : 20, triggerOffsetY : 80, mobile : { triggerSize : 'small', triggerPositionX : 'left', triggerPositionY : 'bottom', triggerOffsetX : 10, triggerOffsetY : 30, triggerRadius : '60' } }); }; h.appendChild(s); })();`,
        }}
      />
      <Script
        type="text/javascript"
        id="yotpo-script"
        strategy="afterInteractive"
        src={`https://staticw2.yotpo.com/${process.env.NEXT_PUBLIC_YOTPO_KEY}/widget.js`}
      />
      <Script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
          var createReplacementCookie = function (cookieDomain) {
            function getParameterByName(name, url = window.location.href) {
                name = name.replace(/[\\[\\]]/g, '\\$&');
                var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'), results = regex.exec(url);
                if (!results) return null;
                if (!results[2]) return '';
                return decodeURIComponent(results[2].replace(/\\+/g, ' '));
            }

            function ticksToDate(ticks) {
                return Number.isInteger(ticks) ? new Date(ticks / 1e+4 + new Date('0001-01-01T00:00:00Z').getTime()) : null;
            }

            //* If URL contains the query parameter replacementExpire, create cookie GE_Replacement
            let replacementExpireParam = parseInt(getParameterByName('replacementExpire'));
            if (replacementExpireParam !== null && replacementExpireParam > Date.now()) {
                var cookieStringifiedValue = encodeURIComponent(JSON.stringify({ glCountry: getParameterByName('glCountry'), glCurrency: getParameterByName('glCurrency') }));
                var expirationInUTC = ticksToDate(replacementExpireParam).toUTCString();
                document.cookie = 'GE_Replacement=' + cookieStringifiedValue + ';expires=' + expirationInUTC + ';path=/;domain=.' +cookieDomain;
              }
            };

            var cookieDomain = "cutsclothing.com"; //can require custom value on stores whene checkout page domain is different
            createReplacementCookie(cookieDomain );`,
        }}
      />
      <Script
        async
        id="postscript-sdk"
        strategy="beforeInteractive"
        type="text/javascript"
        src="https://sdk.postscript.io/sdk.bundle.js?shopId=340824"
      />
      <Script
        id="fuego-script"
        src="https://cdn.fuego.io/banner/AKZutFF4IQ.js"
        async
      />
    </>
  );
};

export default Libs;
