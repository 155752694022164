import { useRef } from 'react';
import { Container } from 'theme-ui';
import { useCartAddDiscountUrl } from '@backpackjs/storefront';

import { useSetHeaderHeightCssVar } from '@hooks';

import { PromoBar } from './PromoBar';
import { RotatingBar } from './RotatingBar';
import { Menu } from './Menu';
import { MenuSidebarShell } from './MenuSidebar';
import { MenuDrawerShell } from './MenuDrawer';
import { themed } from './Header.theme';
import { useHeader } from './useHeader';

export const Header = themed(({ theme }) => {
  const headerRef = useRef(null);
  const rotatingBarRef = useRef(null);
  const promoBarRef = useRef(null);

  const {
    isLocked,
    transparentBGEnabled,
    setTransparentBGEnabled,
    stickyTopPosition,
  } = useHeader({ promoBarRef, rotatingBarRef });

  // Auto apply discount if present in URL
  useCartAddDiscountUrl({
    queryParams: ['promo', 'discountCode', 'discount_code'],
    removeParams: false,
  });

  // Sets --header-height CSS var
  useSetHeaderHeightCssVar({ headerRef });

  return (
    <>
      <RotatingBar ref={rotatingBarRef} />
      <PromoBar ref={promoBarRef} rotatingBarRef={rotatingBarRef} />

      <Container
        data-comp={Header.displayName}
        as="header"
        onMouseEnter={() => !isLocked && setTransparentBGEnabled(false)}
        onMouseLeave={() =>
          !isLocked && setTimeout(() => setTransparentBGEnabled(true), 100)
        }
        ref={headerRef}
        sx={theme.header({
          stickyTopPosition,
          transparentBGEnabled,
        })}
      >
        {/* logo, top level menu items, search, account cart */}
        <Menu transparentBGEnabled={transparentBGEnabled} />

        {/* mobile: menu sliding sidebar on menu click */}
        <MenuSidebarShell />

        {/* desktop: mega-nav drawer on hover */}
        <MenuDrawerShell />
      </Container>
    </>
  );
});

Header.displayName = 'Header';
