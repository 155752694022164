import { useBreakpoint } from '@hooks';

export const InstallApp = () => {
  const { s } = useBreakpoint();
  const isMobile = s;
  if (!isMobile) return null;
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '8px',
      }}
    >
      <a
        href="https://play.google.com/store/apps/details?id=com.fuego.cutsclothing"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="https://cdn.fuego.io/google-play-black.png"
          alt="Get it on Google Play"
          style={{ display: 'block', height: '40px' }}
        />
      </a>
      <a
        href="https://apps.apple.com/app/6714447215"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="https://cdn.fuego.io/app-store-black.png"
          alt="Download on the App Store"
          style={{ display: 'block', height: '40px' }}
        />
      </a>
    </div>
  );
};
