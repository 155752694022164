import { Widget } from '@typeform/embed-react';
import { useBreakpoint } from '@hooks';
import { Section } from '@snippets';

import { Schema } from './TypeFormWidget.schema';

export const TypeFormWidget = ({ cms }) => {
  const { widgetId, widgetHeightMobile, widgetHeightDesktop, section } = cms;

  const { s, _m } = useBreakpoint();
  const isMobile = s || _m;

  if (!widgetId) return null;

  return (
    <Section section={section}>
      <Widget
        id={widgetId}
        style={{
          width: '100%',
          height: isMobile ? widgetHeightMobile : widgetHeightDesktop,
        }}
      />
    </Section>
  );
};

TypeFormWidget.displayName = 'TypeFormWidget';
TypeFormWidget.Schema = Schema;
