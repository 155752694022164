import { section } from '@settings/common';

export function Schema() {
  return {
    label: 'TypeForm Widget',
    key: 'typeform-widget',
    fields: [
      {
        label: 'Widget Id',
        name: 'widgetId',
        component: 'text',
      },
      {
        label: 'Widget Height (Mobile)',
        name: 'widgetHeightMobile',
        component: 'number',
      },
      {
        label: 'Widget Height (Desktop)',
        name: 'widgetHeightDesktop',
        component: 'number',
      },
      {
        ...section,
        defaultValue: {
          bgColor: '#F2F2F2',
          mobile: {
            container: 'container',
            mt: 'none',
            py: 'm',
          },
          desktop: {
            container: 'content',
            mt: 'none',
            py: 'm',
          },
        },
      },
    ],
  };
}
